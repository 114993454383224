<template>
  <div class="container">
    <!-- 输入快递单号 -->
    <el-dialog
      :title="titles"
      :visible.sync="visibleShow"
      append-to-body
      @close="closeDias"
      width="40%"
      center
    >
      <div class="inputClasBox">
        <div style="width: 120px">{{titles}}:</div>
        <el-input
          type="textarea"
          rows="3"
          v-model="inputNumber"
          placeholder="请输入,多个单号用逗号(,)隔开"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showInputBoxBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    showInputBox: {
      type: Boolean,
      default: false,
    },
    titles: {
      type: String,
      default: '输入快递单号',
    },
    inputNumbers: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputNumber: "",
      visibleShow: false,
    };
  },
  created() {},
  mounted() {},
  watch: {
    inputNumbers(a, b) {
      this.inputNumber = a;
    },
    showInputBox(a, b) {
      this.visibleShow = a;
    },
  },
  methods: {
    closeDias() {
      this.$emit("closeDiasInputVal", false);
    },
    showInputBoxBtn() {
      let val = this.inputNumber;
      this.$emit("showInputBoxBtn", val);
    },
  },
};
</script>

<style scoped lang="scss">
.inputClasBox {
  display: flex;
}
</style>
